/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getIndividual = /* GraphQL */ `query GetIndividual($id: ID!) {
  getIndividual(id: $id) {
    id
    phoneNumber
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetIndividualQueryVariables,
  APITypes.GetIndividualQuery
>;
export const listIndividuals = /* GraphQL */ `query ListIndividuals(
  $filter: ModelIndividualFilterInput
  $limit: Int
  $nextToken: String
) {
  listIndividuals(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      phoneNumber
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListIndividualsQueryVariables,
  APITypes.ListIndividualsQuery
>;
export const individualsByPhoneNumber = /* GraphQL */ `query IndividualsByPhoneNumber(
  $phoneNumber: String!
  $sortDirection: ModelSortDirection
  $filter: ModelIndividualFilterInput
  $limit: Int
  $nextToken: String
) {
  individualsByPhoneNumber(
    phoneNumber: $phoneNumber
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      phoneNumber
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.IndividualsByPhoneNumberQueryVariables,
  APITypes.IndividualsByPhoneNumberQuery
>;
export const getTeam = /* GraphQL */ `query GetTeam($id: ID!) {
  getTeam(id: $id) {
    id
    managerCognitoId
    managerPhoneNumber
    name
    invites
    members
    names
    batonHolder
    lastBatonPassTime
    badges
    locked
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTeamQueryVariables, APITypes.GetTeamQuery>;
export const listTeams = /* GraphQL */ `query ListTeams(
  $filter: ModelTeamFilterInput
  $limit: Int
  $nextToken: String
) {
  listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      managerCognitoId
      managerPhoneNumber
      name
      invites
      members
      names
      batonHolder
      lastBatonPassTime
      badges
      locked
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListTeamsQueryVariables, APITypes.ListTeamsQuery>;
export const teamsByManagerCognitoId = /* GraphQL */ `query TeamsByManagerCognitoId(
  $managerCognitoId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelTeamFilterInput
  $limit: Int
  $nextToken: String
) {
  teamsByManagerCognitoId(
    managerCognitoId: $managerCognitoId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      managerCognitoId
      managerPhoneNumber
      name
      invites
      members
      names
      batonHolder
      lastBatonPassTime
      badges
      locked
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TeamsByManagerCognitoIdQueryVariables,
  APITypes.TeamsByManagerCognitoIdQuery
>;
export const teamsByManagerPhoneNumber = /* GraphQL */ `query TeamsByManagerPhoneNumber(
  $managerPhoneNumber: String!
  $sortDirection: ModelSortDirection
  $filter: ModelTeamFilterInput
  $limit: Int
  $nextToken: String
) {
  teamsByManagerPhoneNumber(
    managerPhoneNumber: $managerPhoneNumber
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      managerCognitoId
      managerPhoneNumber
      name
      invites
      members
      names
      batonHolder
      lastBatonPassTime
      badges
      locked
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TeamsByManagerPhoneNumberQueryVariables,
  APITypes.TeamsByManagerPhoneNumberQuery
>;
export const getEvent = /* GraphQL */ `query GetEvent($id: ID!) {
  getEvent(id: $id) {
    id
    name
    invitesSent
    teams
    started
    archived
    test
    minimumDistance
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetEventQueryVariables, APITypes.GetEventQuery>;
export const listEvents = /* GraphQL */ `query ListEvents(
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      invitesSent
      teams
      started
      archived
      test
      minimumDistance
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEventsQueryVariables,
  APITypes.ListEventsQuery
>;
export const getEventData = /* GraphQL */ `query GetEventData($id: ID!) {
  getEventData(id: $id) {
    id
    event
    team
    phoneNumber
    timeStarted
    timeFinished
    distance
    s3Key
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEventDataQueryVariables,
  APITypes.GetEventDataQuery
>;
export const listEventData = /* GraphQL */ `query ListEventData(
  $filter: ModelEventDataFilterInput
  $limit: Int
  $nextToken: String
) {
  listEventData(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      event
      team
      phoneNumber
      timeStarted
      timeFinished
      distance
      s3Key
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEventDataQueryVariables,
  APITypes.ListEventDataQuery
>;
export const getBadge = /* GraphQL */ `query GetBadge($id: ID!) {
  getBadge(id: $id) {
    id
    name
    s3Key
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetBadgeQueryVariables, APITypes.GetBadgeQuery>;
export const listBadges = /* GraphQL */ `query ListBadges(
  $filter: ModelBadgeFilterInput
  $limit: Int
  $nextToken: String
) {
  listBadges(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      s3Key
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBadgesQueryVariables,
  APITypes.ListBadgesQuery
>;
export const getPurchase = /* GraphQL */ `query GetPurchase($id: ID!) {
  getPurchase(id: $id) {
    id
    team
    event
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPurchaseQueryVariables,
  APITypes.GetPurchaseQuery
>;
export const listPurchases = /* GraphQL */ `query ListPurchases(
  $filter: ModelPurchaseFilterInput
  $limit: Int
  $nextToken: String
) {
  listPurchases(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      team
      event
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPurchasesQueryVariables,
  APITypes.ListPurchasesQuery
>;
export const getChatGPTOutput = /* GraphQL */ `query GetChatGPTOutput($id: ID!) {
  getChatGPTOutput(id: $id) {
    id
    initial_timezone
    distance
    duration
    timeStarted
    timeFinished
    location
    timezone
    date
    dateEnded
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetChatGPTOutputQueryVariables,
  APITypes.GetChatGPTOutputQuery
>;
export const listChatGPTOutputs = /* GraphQL */ `query ListChatGPTOutputs(
  $filter: ModelChatGPTOutputFilterInput
  $limit: Int
  $nextToken: String
) {
  listChatGPTOutputs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      initial_timezone
      distance
      duration
      timeStarted
      timeFinished
      location
      timezone
      date
      dateEnded
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListChatGPTOutputsQueryVariables,
  APITypes.ListChatGPTOutputsQuery
>;
